import Header from '@Components/Header';

import { Container } from 'react-bootstrap';
import { LoginForm } from '@Components/Login';



function Home() {
    return <>
        <Header />

        <Container
            //boorstrap class center flexflow=column
            className='d-flex flex-column align-items-center justify-content-center mt-3'
        >

            <img src='./logo512.png' alt='Logo' style={{
                width: 150,
            }} />

            <Container
                className='mt-3'
                style={{
                    maxWidth: 250,
                }}
            >
                <LoginForm />
            </Container>
        </Container>
    </>
}

export default Home;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import { Form, Modal } from 'react-bootstrap';
import Select from 'react-select';



const cityOptions = [
    { value: 'madrid', label: 'Madrid' },
    { value: 'miami', label: 'Miami' },
    { value: 'new_york', label: 'New York' },
    // Add more city options as needed
];

const currencyOptions = [
    { value: 'usd', label: 'USD', symbol: '$' },
    { value: 'eur', label: 'EUR', symbol: '€' },
    // Add more currency options as needed
];
const languageOptions = [
    { value: 'en', label: 'English', flag: '🇺🇸' },
    { value: 'es', label: 'Español', flag: '🇪🇸' },
    // Add more language options as needed
];

const SettingsGeneral = (
    {
        setShow
    }
) => {

    const { t } = useTranslation();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
    } = dataReducer;
    const [selectedTimezone, setSelectedTimezone] = React.useState('');
    const [selectedCurrency, setSelectedCurrency] = React.useState(null);
    const [selectedLanguage, setSelectedLanguage] = React.useState(null);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectedCity, setSelectedCity] = React.useState(null);



    const timezoneOptions = moment.tz.names();

    const deliveryOptions = [
        { value: 'Delivery', label: 'Delivery' },
        { value: 'Pickup', label: 'Pickup' },
        { value: 'DineIn', label: 'Dine In' },
    ];

    const handleTimezoneChange = (selectedOption) => {
        setSelectedTimezone(selectedOption);
    };
    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
    };

    const handleLanguageChange = (selectedOption) => {
        setSelectedLanguage(selectedOption);
    };
    const handleDeliveryOptionsChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };




    React.useEffect(() => {
        // Get browser's timezone and set it as the default value for the timezone select
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setSelectedTimezone({ value: browserTimezone, label: browserTimezone });
    }, []);

    return <>
        <Modal.Header closeButton>
            <Modal.Title>{t('settings_general')}</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
            <Form >
                <Form.Group controlId="timezoneSelect"
                    className='mb-3'
                >
                    <Select
                        options={timezoneOptions.map((timezone) => ({ value: timezone, label: timezone }))}
                        value={selectedTimezone}
                        onChange={handleTimezoneChange}
                        isClearable
                        placeholder={t('settings_select_timezone')}
                    />

                </Form.Group>
                <Form.Group controlId="currencySelect"
                    className='mb-3'
                >
                    <Select
                        options={currencyOptions.map((currency) => ({
                            value: currency.value,
                            label: `${currency.symbol} ${currency.label}`,
                        }))}
                        value={selectedCurrency}
                        onChange={handleCurrencyChange}
                        isClearable
                        placeholder={t('settings_select_currency')}
                    />
                </Form.Group>

                <Form.Group controlId="languageSelect"
                    className='mb-3'
                >
                    <Select
                        options={languageOptions.map((language) => ({
                            value: language.value,
                            label: `${language.flag} ${language.label}`,
                        }))}
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        isClearable
                        placeholder={t('settings_select_language')}
                    />
                </Form.Group>

                <Form.Group controlId="citySelect"
                    className='mb-3'
                >
                    <Select
                        options={cityOptions}
                        value={selectedCity}
                        onChange={handleCityChange}
                        isClearable
                        placeholder={t('settings_select_city')}
                    />
                </Form.Group>



                <Form.Group controlId="deliverySelect"
                    className='mb-3'
                >
                    <Select
                        options={deliveryOptions}
                        value={selectedOptions}
                        onChange={handleDeliveryOptionsChange}
                        isMulti
                        placeholder={t('settings_select_modes')}
                    />
                </Form.Group>
            </Form>

            <img
                alt=""
                src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`}

                height="30"
                className="d-inline-block align-top"
            />

        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={() => setShow(false)} data-bs-dismiss="modal">{t('Close')}</button>
            <button type="button" className="btn btn-primary">{t('Save changes')}</button>
        </Modal.Footer>

    </>

}

export default SettingsGeneral;
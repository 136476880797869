const root = process.env.REACT_APP_API;
const api_key = process.env.REACT_APP_API_KEY;
let token = localStorage.getItem('auth_token');
let restaurant_id = localStorage.getItem('rid');

const api = {

    order_send_template: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/r/${restaurant_id}/orders/${args.payload.order_id}/msg/`;
            url = `${url}?message_template=${args.payload.message_template}`

            console.warn("url", url)

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'POST'
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    order_status_update: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/r/${restaurant_id}/orders/${args.payload.order_id}/status/${args.payload.status_id}`;

            if (args.payload?.orderStatusFilter) {
                url = `${url}?orderStatus=${args.payload.orderStatusFilter}`
            }
            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'POST'
                //body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    orders_fetch: (args, authTokenState, restaurant) => {
        return new Promise((resolve, reject) => {
            if (!token) {
                token = authTokenState;
            }
            if (!restaurant_id) {
                restaurant_id = restaurant.id;
            }

            let url = `${root}/r/${restaurant_id}/orders/`;

            if (args.payload?.orderStatusFilter) {
                url = `${url}?orderStatus=${args.payload.orderStatusFilter}`
            }
            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET'
                //body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

}

api.auth = {
    //https://e5itz5j0m7.execute-api.us-east-1.amazonaws.com/beta/r/auth

    login: (args) => {
        return new Promise((resolve, reject) => {
            let url = `${root}/r/auth`;
            var headers = new Headers();
            headers.append("x-api-key", api_key);

            fetch(url, {
                headers,
                method: args.method,
                body: JSON.stringify(args.body)
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}

api.menu = {

    fetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/r/${restaurant_id}/food-items/?1=1`;

            if (args.payload?.cat_id) {
                url += `&cat_id=${args.payload.cat_id}`
            }
            if (args.payload?.menu_status) {
                url += `&menu_status=${args.payload.menu_status}`
            }
            if (args.payload?.publish_status) {
                url += `&publish_status=${args.payload.publish_status}`
            }

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET'
                //body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}

api.categories = {
    //r/{restaurant_id}/food-categories

    fetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/r/${restaurant_id}/food-categories/?1=1`;

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET'
                //body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}
export default api;
import { combineReducers } from 'redux';

let dataState = {
    fetching: false,

    order: {
        order_items_json: '[]'
    },
    restaurant: {}

}

const dataReducer = (state = dataState, action) => {

    switch (action.type) {

        case 'FOOD_ITEM_SAVE':
            //todo: MOVE TO SAGA
            return {
                ...state,

                fetching: {
                    ...state.fetching || {},
                    food_item_save: null
                }
            }

        case 'FOOD_ITEM_PHOTO_BASE64_SET':
            return {
                ...state,
                photo_base64: action.data.photo_base64

            }



        case 'RESTAURANT_SET':
            return {
                ...state,
                restaurant: action.data.restaurant,
                authToken: action.data.authToken
            }

        case 'FETCHING':
            return {
                ...state,
                fetching: action.fetching
            }
        case 'ORDERS_SET':
            return {
                ...state,
                orders: action.data
            }

        case 'ORDER_SET':
            return {
                ...state,
                order: action.payload.order
            }

        case 'CATEGORIES_SET':

            return {
                ...state,
                categories: action.data.food_categories
            }

        case 'ORDER_UPDATE':

            return {
                ...state,
                order: {
                    ...state.order,
                    ...action.data.order_updated,
                },
                orders: {
                    ...state.orders,
                    orders: state.orders.orders.map(order => {
                        if (order.id === action.data.order_updated.id) {
                            return {
                                ...order,
                                ...action.data.order_updated
                            };
                        }
                        return order;
                    })
                }


            }

        case 'MENU_SET':
            return {
                ...state,
                menu: action.data.food_items
            }


        case 'FOOD_ITEM_SET':

            return {
                ...state,
                food_item: action.data.food_item
            }

        case 'LOGOUT':

            localStorage.setItem('rid', "0");
            localStorage.setItem('restaurant', JSON.stringify({}));
            return {
                ...state,
                menu: null,
                orders: null,
                restaurant: {},
                order: {
                    order_items_json: '[]'
                },
            }

        default:
            return state;
    }

}


const rootReducer = combineReducers({
    dataReducer,
})


export default rootReducer;

import { call, put, takeLatest, select } from 'redux-saga/effects';

import api from '@Services/api';

const getAuthToken = (state) => state.dataReducer.authToken;
const getRestaurant = (state) => state.dataReducer.restaurant;

function* ordersFetch(action) {
    try {
        let authToken = yield select(getAuthToken);
        let restaurant = yield select(getRestaurant);
        const data = yield call(api.orders_fetch, action, authToken, restaurant);
        yield put({ type: "ORDERS_SET", data });
        yield put({ type: "FETCHING", fetching: null });

    } catch (e) {
        console.warn("ordersFetch", e)
        //yield put({ type: "FOOD_CATEGORIES_FAIL", message: e.message });
    }
}


function* fnOrderStatusUpdate(action) {
    try {
        yield put({ type: "FETCHING", fetching: 'order_status_update' });
        const data = yield call(api.order_status_update, action);
        yield put({ type: "ORDER_UPDATE", data });
        yield put({ type: "FETCHING", fetching: null });

    } catch (e) {
        console.warn("ordersFetch", e)
        //yield put({ type: "FOOD_CATEGORIES_FAIL", message: e.message });
    }
}

function* fnOrderSendTemplateMessage(action) {
    try {
        yield put({ type: "FETCHING", fetching: 'order_message_sending' });
        const data = yield call(api.order_send_template, action);
        console.log(data);
        //yield put({ type: "ORDER_UPDATE", data });
        yield put({ type: "FETCHING", fetching: null });

    } catch (e) {
        console.warn("fnOrderSendTemplateMessage", e)
        //yield put({ type: "FOOD_CATEGORIES_FAIL", message: e.message });
    }
}




function* menuFetch(action) {
    try {
        const data = yield call(api.menu.fetch, action);
        yield put({ type: "MENU_SET", data });
        yield put({ type: "FETCHING", fetching: null });

    } catch (e) {
        console.warn("menuFetch", e)
        //yield put({ type: "FOOD_CATEGORIES_FAIL", message: e.message });
    }
}

function* categoriesFetch(action) {
    try {
        const data = yield call(api.categories.fetch, action);
        console.log("categoriesFetch", data)
        yield put({ type: "CATEGORIES_SET", data });
        //yield put({ type: "FETCHING", fetching: null });

    } catch (e) {
        console.warn("menuFetch", e)
        //yield put({ type: "FOOD_CATEGORIES_FAIL", message: e.message });
    }
}
export default function* rootSaga() {

    yield takeLatest("ORDERS_FETCH", ordersFetch);
    yield takeLatest("ORDER_STATUS_UPDATE", fnOrderStatusUpdate);
    yield takeLatest("ORDER_SEND_TEMPLATE_MESSAGE", fnOrderSendTemplateMessage);



    yield takeLatest("MENU_FETCH", menuFetch);
    yield takeLatest("CATEGORIES_FETCH", categoriesFetch);
}



import React from 'react';

import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useTranslation, Trans } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import api from '@Services/api';
import { useDispatch } from 'react-redux';
var CryptoJS = require("crypto-js");

const LoginForm = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [password, setPassword] = React.useState('');

    //recover phone from localStorage if it exists
    const [login, setLogin] = React.useState(localStorage.getItem('phone') || '');
    const [fetching, setFetching] = React.useState(false);

    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //if onload localStorage.getItem('restaurant') exists, then redirect to admin page
    //only one time
    React.useEffect(() => {
        let _r = JSON.parse(localStorage.getItem('restaurant'));
        if (_r && _r.stub) {
            navigate(`/admin/${_r.stub}/`);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const fnLogin = () => {
        let errors = [];
        if (login === '') {
            errors.push('Please enter your phone number');
        }
        if (password === '') {
            errors.push('Please enter your password');
        }
        if (errors.length > 0) {
            alert(errors.join('\n'));
            return;
        }

        setFetching('fetching-login');


        var data = { login, password };
        var cipher = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_AUTHSECRET).toString();


        api.auth.login({
            method: 'POST',
            body: {
                cipher
            }
        })
            .then((response) => {
                setFetching(false);
                if (!response.error && response.authtoken) {
                    localStorage.setItem('phone', login);
                    localStorage.setItem('auth_token', response.authtoken);
                    localStorage.setItem('rid', response.rid);
                    localStorage.setItem('saga', JSON.stringify(response.saga));
                    localStorage.setItem('restaurant', JSON.stringify(response.restaurant));

                    dispatch({
                        type: 'RESTAURANT_SET',
                        data: {
                            restaurant: response.restaurant,
                            authToken: response.authtoken,
                        }
                    });
                    dispatch({
                        type: 'ORDERS_FETCH',
                        payload: {
                            orderStatusFilter: 'open'
                        }
                    });
                    navigate(`/admin/${response.stub}/`);
                } else {
                    alert(response.msg);
                }
            })
            .catch((error) => {
                setFetching(false);
                console.log(error);
            });

    }

    return <Container>
        <Form.Group

            className="mb-3" controlId="login">
            <Form.Label>Phone</Form.Label>
            <input className="form-control border-end-0 border rounded-pill input-border-black"
                type="text"
                onChange={(e) => {
                    setLogin(e.target.value)
                }}
                value={login}
            />

        </Form.Group>

        <Form.Group

            className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <input className="form-control border-end-0 border rounded-pill input-border-black"
                type="password"
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                value={password}
            />
        </Form.Group>

        <div>
            <Button variant="primary" size="lg"
                disabled={fetching === 'fetching-login'}
                onClick={fnLogin}
                style={{
                    width: 100
                }}
            >
                {fetching === 'fetching-login' ? <Spinner animation="border" size="sm" /> : t('Login')}
            </Button>
        </div>

        <Button variant="link"

            className='mt-3'
            onClick={handleShow}
        >
            {t('Change Password')}
        </Button>

        <Modal
            show={show}
            size="sm"
            onHide={handleClose}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Password Reset</Modal.Title>
            </Modal.Header>


            <Modal.Body>
                <Trans
                    i18nKey="login_password_reset"
                    values={{
                        phone: '+1(954)519-6267'
                    }}

                />
                <br />
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://wa.me/19545196267?text=${encodeURIComponent('I forgot my password')}`}

                >
                    +1(954)519-6267
                </a>
            </Modal.Body>


        </Modal>
    </Container >
}


export {
    LoginForm
}


import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Container, Modal } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'


const CellPhoneForm = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        cellPhone1: '',
        cellPhone2: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here, you can perform any actions with the form data, such as submitting it to a server or performing validation.
        console.log('Form data:', formData);
    };

    return (
        <div
            style={{
                maxWidth: 230
            }}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="cellPhone1">
                    <Form.Label>{t('whatsapp_customer_service')}</Form.Label>
                    <Form.Control
                        type="tel"
                        name="cellPhone1"
                        value={formData.cellPhone1}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="cellPhone2">
                    <Form.Label>{t('whatsapp_manager')}</Form.Label>
                    <Form.Control
                        type="tel"
                        name="cellPhone2"
                        value={formData.cellPhone2}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

            </Form>
            Default = Saga Menu Whatsapp Gerencia & Servicio al Cliente
        </div>
    );
};


const WhatsApp = ({
    setShow
}) => {
    const { t } = useTranslation();

    const [checked, setChecked] = React.useState(false); //eslint-disable-line
    return <>
        <Modal.Header closeButton>
            <Modal.Title>{t('settings_whatsapp')}</Modal.Title>
        </Modal.Header>
        <Modal.Body

        >
            <Container className='p-0'>
                <CellPhoneForm />
                <hr />
                <div>
                    {t('settings_whatsapp_gpt')}
                    <br />
                    <BootstrapSwitchButton
                        width={50}
                        checked={false}
                        onlabel='Yes'
                        offlabel='No'
                        onChange={(checked) => {
                            setChecked(checked)
                        }}
                        onstyle="success"
                        offstyle="danger"
                    />
                </div>

                <div>
                    {t('settings_whatsapp_payment_reminders')}
                    <br />
                    <BootstrapSwitchButton
                        width={50}
                        checked={false}
                        onlabel='Yes'
                        offlabel='No'
                        onChange={(checked) => {
                            setChecked(checked)
                        }}
                        onstyle="success"
                        offstyle="danger"
                    />
                </div>

                <div>
                    {t('settings_whatsapp_order_status')}
                    <br />
                    <BootstrapSwitchButton
                        width={50}
                        checked={false}
                        onlabel='Yes'
                        offlabel='No'
                        onChange={(checked) => {
                            setChecked(checked)
                        }}
                        onstyle="success"
                        offstyle="danger"
                    />
                </div>

            </Container>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={() => setShow(false)} data-bs-dismiss="modal">{t('Close')}</button>
            <button type="button" className="btn btn-primary">{t('Save changes')}</button>
        </Modal.Footer>

    </>
}


export default WhatsApp;
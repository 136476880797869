import React from 'react';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Navbar, } from 'react-bootstrap';

const Header = () => {

    const navigate = useNavigate();
    // eslint-disable-next-line 
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
    } = dataReducer;

    React.useEffect(() => {
        if (restaurant?.id) {
            i18n.changeLanguage(restaurant.locale);
        } else {
            dispatch({
                type: 'RESTAURANT_SET',
                data: {
                    restaurant: JSON.parse(localStorage.getItem('restaurant') || "{}")
                }
            })
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {

        if (!localStorage.getItem('auth_token')) {
            dispatch({
                type: 'LOGOUT'
            });
            navigate('/login');
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps




    return <>
        <Navbar bg="light" expand="lg"
            style={{
                position: 'sticky',
                top: 0,
                //zIndex: 100,
            }}
        >
            <Container>
                <Navbar.Brand
                    onClick={() => { navigate(`/admin/${restaurant?.stub}/`) }}
                    style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                >
                    <img
                        alt=""
                        src="/logo192.png"
                        height="30"
                        width="30"
                        className="d-inline-block align-top me-2"
                    />
                    <div
                        style={{ flex: 1 }}
                        className='d-flex justify-content-center align-items-center'
                    >{JSON.parse(localStorage.getItem('saga') || "{}").r_name}</div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to={`/admin/${restaurant?.stub}/`} >
                            <Nav.Link  >{t('Dashboard')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/admin/${restaurant?.stub}/orders`} >
                            <Nav.Link  >{t('Orders')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/admin/${restaurant?.stub}/menu`} >
                            <Nav.Link  >{t('Menu')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/admin/${restaurant?.stub}/reports`} >
                            <Nav.Link  >{t('Reports')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/admin/${restaurant?.stub}/settings`} >
                            <Nav.Link  >{t('settings')}</Nav.Link>
                        </LinkContainer>

                        <Nav.Link
                            onClick={() => {
                                dispatch({
                                    type: 'LOGOUT'
                                });
                                localStorage.removeItem('auth_token');
                                localStorage.removeItem('rid');
                                localStorage.removeItem('saga');
                                navigate('/login');
                            }}
                        >{t('Logout')}</Nav.Link>


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </>


}

export default Header;


import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';


import { Table, Form, Button } from 'react-bootstrap';

const Users = ({
    setShow
}) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);


    const [users, setUsers] = useState([
        { id: 1, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890', role: 'Admin' },
        { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '987-654-3210', role: 'User' },
        // Add more users as needed
    ]);

    const [formData, setFormData] = useState({ id: '', name: '', email: '', phone: '', role: '' });

    const handleEdit = (user) => {
        setShowModal(true)
        setFormData(user);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.id) {
            // Edit existing user
            setUsers((prevUsers) =>
                prevUsers.map((user) => (user.id === formData.id ? formData : user))
            );
        } else {
            // Add new user
            setUsers((prevUsers) => [...prevUsers, { ...formData, id: Date.now() }]);
        }
        setFormData({ id: '', name: '', email: '', phone: '', role: '' });
    };

    return <>

        <Modal

            backdropClassName='dialog-edit-backdrop'
            className='dialog-edit'
            show={showModal}
            onHide={() => setShowModal(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {t('settings_users')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="role">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                            as="select"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Role</option>
                            <option value="Admin">Admin</option>
                            <option value="User">User</option>
                            {/* Add more roles as needed */}
                        </Form.Control>
                    </Form.Group>

                </Form>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    {t('Close')}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t('Save changes')}
                </Button>
            </Modal.Footer>

        </Modal>





        <Modal.Header closeButton>
            <Modal.Title>{t('settings_users')}</Modal.Title>
        </Modal.Header>
        <Modal.Body
        >


            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}
                            onClick={() => handleEdit(user)}
                        >

                            <td>{user.name}
                                <br />
                                {user.role}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>


        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-success">{t('New User')}</button>
        </Modal.Footer>

    </>
}

export default Users;


import React from 'react';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'

import { useTranslation } from "react-i18next";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {

    const { t } = useTranslation();
    return <>
        <Navbar bg="light" expand="lg"
            className='navbar-fixed-top'

        >
            <Container>

                <Navbar.Brand

                >
                    <LinkContainer to={`/`} >
                        <Nav.Link  >
                            <img
                                alt=""
                                src="/logo192.png"
                                height="30"
                                width="30"
                                className="d-inline-block align-top"
                            />{' '}
                            &nbsp;&nbsp;

                            Saga Menu

                        </Nav.Link>
                    </LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                        <LinkContainer to={`/`} >
                            <Nav.Link  >{t('Home')}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/login`} >
                            <Nav.Link  >{t('Login')}</Nav.Link>
                        </LinkContainer>


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >

    </>


}

export default Header;
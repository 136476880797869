import Header from '@Components/Header';

import { LoginForm } from '@Components/Login';
import { Container } from 'react-bootstrap';



function Login() {
    return <>
        <Header />
        <Container
            className='mt-5'
            style={{
                maxWidth: 250,
            }}
        >
            <LoginForm />
        </Container>
    </>
}

export default Login;

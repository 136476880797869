

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'es',
        debug: false,
        resources: {
            en: {
                translation: {
                    "options": "Options",
                    "Orders": "Orders",
                    "order": "Order",
                    "Logout": "Logout",
                    "Reports": "Reports",
                    "Login": "Login",
                    "Home": "Home",
                    "pickups_deliveries": "Pickups & Deliveries",
                    "on_site": "On Site",
                    "pickup": "Pickup",
                    "delivery": "Delivery",
                    "Paid": "Paid",
                    "Not Paid": "Not Paid",
                    "btn_close": "Close",
                    "btn_order_ready": "Order Ready",
                    "btn_order_pending": "Back to Pending",
                    "btn_order_finish": "Finish",
                    "btn_back_to_ready": "Back to Ready",

                    "status_pending": "Pending",
                    "status_ready": "Ready",
                    "status_delivered": "Delivered",


                    "orders_active": "Active",
                    "orders_past": "Past",

                    "btn_search": "Search",
                    "order_number": "Order Number",
                    "search": "Search",
                    "order_status_pending": "Pendiente",
                    "order_status_ready": "Ready",
                    "order_status_done": "Done",
                    "phone_number_or_name": "Phone Number or Name",

                    "save": "Save",
                    "required_options": "Required Option",
                    "extras": "Extras",
                    "publish": "Publish",
                    "sold_out": "Sold Out",
                    "featured": "Featured",

                    "settings": "Settings",
                    "settings_general": "General",
                    "settings_whatsapp": "Whatsapp",
                    "settings_opening_hours": "Opening Hours",
                    "settings_users": "Users",
                    "settings_deliveries": "Deliveries",
                    "settings_select_timezone": "Select Timezone",
                    "settings_select_language": "Select Language",
                    "settings_select_currency": "Select Currency",
                    "settings_select_modes": "Select Modes",
                    "settings_select_city": "Select City",

                    "settings_whatsapp_gpt": "Whatsapp GPT",
                    "settings_whatsapp_payment_reminders": "Whatsapp Payment Reminders",
                    "settings_whatsapp_order_status": "Whatsapp Order Status",


                    "day": "Day",
                    "open": "Open",
                    "close": "Close",

                    "copied_to_clipboard": "Copied to Clipboard",

                    "menu_published": "Published",
                    "menu_unpublished": "Unpublished",
                    "menu_all": "All",

                    "whatsapp_customer_service": "Customer Service",
                    "whatsapp_manager": "Manager",

                    "file_upload": "File Upload",
                    "new_item": "New",
                    "login_password_reset": "Request password reset to whatsapp number {{phone}}",

                    "Save changes": "Save Changes",
                    "Close": "Close",
                    "New User": "New User",
                    "not_published": "Not Published",

                    "delete": "Delete",

                    "btn_send_message": "Send Message",
                    "order_is_ready": "Order is Ready",
                    "confirm_send_message": "Are you sure you want to send a message?",
                }
            },
            es: {
                translation: {
                    "options": "Opciones",
                    "Orders": "Pedidos",
                    "order": "Orden",
                    "Logout": "Salir",
                    "Reports": "Reportes",
                    "Login": "Login",
                    "Home": "Inicio",
                    "pickups_deliveries": "Pickups & Entregas",
                    "on_site": "En Sitio",
                    "pickup": "Pickup",
                    "delivery": "Entrega",
                    "Paid": "Pagado",
                    "Not Paid": "Por Pagar",
                    "btn_close": "Cerrar",
                    "btn_order_ready": "Pedido Listo",
                    "btn_order_pending": "Regresar a Pendiente",
                    "btn_order_finish": "Terminar",
                    "btn_back_to_ready": "Regresar a Listo",


                    "status_pending": "Pendiente",
                    "status_ready": "Listo",
                    "status_delivered": "Entregado",

                    "orders_past": "Pedidos Pasados",
                    "orders_active": "Pedidos Activos",

                    "btn_search": "Buscar",
                    "order_number": "Número de Orden",
                    "search": "Buscar",
                    "order_status_pending": "Pendiente",
                    "order_status_ready": "Listo",
                    "order_status_done": "Terminado",
                    "phone_number_or_name": "Celular o Nombre",

                    "save": "Guardar",
                    "required_options": "Opción Requerida",
                    "extras": "Extras",
                    "publish": "Publicar",
                    "sold_out": "Agotado",
                    "featured": "Destacado",

                    "settings": "Configuración",
                    "settings_general": "General",
                    "settings_whatsapp": "Whatsapp",
                    "settings_opening_hours": "Horarios",
                    "settings_users": "Usuarios",
                    "settings_deliveries": "Entregas",
                    "settings_select_timezone": "Seleccionar Zona Horaria",
                    "settings_select_language": "Seleccionar Idioma",
                    "settings_select_currency": "Seleccionar Moneda",
                    "settings_select_modes": "Seleccionar Modos",
                    "settings_select_city": "Seleccionar Ciudad",

                    "settings_whatsapp_gpt": "Whatsapp GPT",
                    "settings_whatsapp_payment_reminders": "Whatsapp Recordatorios de Pago",
                    "settings_whatsapp_order_status": "Whatsapp Estado de Orden",


                    "Monday": "Lunes",
                    "Tuesday": "Martes",
                    "Wednesday": "Miércoles",
                    "Thursday": "Jueves",
                    "Friday": "Viernes",
                    "Saturday": "Sábado",
                    "Sunday": "Domingo",

                    "day": "Día",
                    "open": "Abre",
                    "close": "Cierra",
                    "copied_to_clipboard": "Copiado al Portapapeles",

                    "menu_published": "Publicados",
                    "menu_unpublished": "No Publicados",
                    "menu_all": "Todos",

                    "Published": "Publicado",
                    "Sold Out": "Agotado",
                    "Featured": "Destacado",

                    "whatsapp_customer_service": "Servicio al Cliente",
                    "whatsapp_manager": "Gerencia",

                    "file_upload": "Archivo",
                    "new_item": "Nuevo",
                    "login_password_reset": "Solicita cambio de contraseña al número de whatsapp {{phone}}",

                    "Save changes": "Guardar Cambios",
                    "Close": "Cerrar",
                    "New User": "Nuevo Usuario",

                    "Please fill the following fields:": "Por favor llena los siguientes campos:",
                    "Name": "Nombre",
                    "Price": "Precio",
                    "Description": "Descripción",
                    "Category": "Categoría",

                    "not_published": "No Publicado",

                    "delete": "Eliminar",

                    "btn_send_message": "Enviar Mensaje",
                    "order_is_ready": "Pedido Está Listo",
                    "confirm_send_message": "¿Estás seguro que quieres enviar un mensaje?",
                }
            }
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;    
import React from 'react';
import HeaderAdmin from '@Components/HeaderAdmin';
import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { IoSearch, IoCheckmarkCircleSharp, IoCopySharp } from "react-icons/io5";
import moment from 'moment-timezone';
import 'moment/locale/es';
// all timezones are Europe/London because they are set to tz 0 in the server

const fnProperCase = (str) => {
    //this function capitalizes leter after accents 
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\b(\p{Ll})/gu, s => s.toUpperCase());

    //return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
}

const OrderDetails = ({
    order,
    restaurant
}) => {
    const { t } = useTranslation();


    return <>
        <div
            //bootstrap class for styles below
            className='d-flex flex-row justify-content-between align-items-center '

        >
            <div>


                <div>
                    <Badge
                        bg={
                            order.order_status_id === 3 ? 'success' : "secondary"
                        }
                    >
                        {
                            order.order_status_id === 3 &&
                            <IoCheckmarkCircleSharp size={20} style={{ marginRight: 5 }} />
                        }

                        {t('order')} {order.id}
                    </Badge>
                </div>
                <div>
                    {
                        order.order_type !== 'on_site' ?
                            <>
                                {fnProperCase(moment(order.ordered_for_day).tz(restaurant?.tz).format("dddd MMM DD"))}
                                &nbsp;
                                {order.time_slot}
                            </>
                            :
                            fnProperCase(moment(order.order_dateTime).tz(restaurant?.tz).format("dddd MMM DD HH:mm"))
                    }


                </div>
                {order.order_type !== 'on_site' &&
                    <div style={{
                        fontSize: '0.8rem',
                    }}>

                        @{fnProperCase(moment(order.order_dateTime).tz(restaurant?.tz).format("dddd DD HH:mm"))}

                    </div>
                }

            </div>

            <div >

                <span
                    style={{
                        color: order.order_paid_dt !== null ? 'green' : 'red'
                    }}
                >
                    {order.order_paid_dt !== null ? t('Paid') : t('Not Paid')}
                </span>
                <div

                >


                    {t('order_status_' + (order.order_status))}
                </div>
            </div>
        </div>


        {JSON.parse(order.order_items_json).map((item, index) => {
            return <div
                className='mb-1 mt-1'
                key={index + item.name}
            >
                <b>{item.units_ordered} {item.name}</b>
                <div
                    style={{
                        paddingLeft: 5,
                        fontSize: '0.9rem',
                        borderLeft: '1px solid #000',
                        borderRadius: 3,
                    }}
                >
                    <div>{item.required_option?.name}</div>
                    {item?.extras_ordered?.length > 0 && <div>

                        {item.extras_ordered.map((extra, index) => {
                            return <div key={index + "_"}>{extra.name}</div>

                        })}
                    </div>
                    }


                    <div>{item.notes}</div>
                </div>

            </div>
        })}

    </>
}


const ModalOrder = ({
    show,
    setShow,
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        order,
        fetching,
        restaurant
    } = dataReducer;

    const fnOrderChangeStatus = (order_id, status_id) => {
        dispatch({
            type: 'ORDER_STATUS_UPDATE',
            payload: {
                order_id,
                status_id
            }
        });
    }

    const fnSendTemplateMessage = (order_id, message_template) => {
        window.confirm(t('confirm_send_message')) &&
            dispatch({
                type: 'ORDER_SEND_TEMPLATE_MESSAGE',
                payload: {
                    order_id,
                    message_template
                }
            });
    }

    return <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{order.id} - {order.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <OrderDetails
                order={order}
                restaurant={restaurant}
            />
            <hr />
            <Container
                className='p-0'
            >
                <div
                    className='d-flex flex-row justify-content-between'

                >
                    <div>
                        {order.name}
                    </div>
                    <div>

                        <a
                            target="_blank" rel="noreferrer" href={`https://wa.me/${order.phone}`}

                        >+{order.phone}</a>
                    </div>
                </div>

                <div>
                    {t(order.order_type)}
                </div>
                {order.order_paid_dt === null &&
                    <div
                        onClick={() => {
                            navigator.clipboard.writeText(order.payment_url);
                            alert(t('copied_to_clipboard'))
                        }}
                        style={{
                            color: 'blue',
                        }}
                    >
                        <IoCopySharp></IoCopySharp> {order.payment_url}
                    </div>
                }

            </Container>


        </Modal.Body>

        <Modal.Footer>
            {
                fetching === 'order_status_update' ?
                    <div className='m-3' >
                        <Spinner animation="grow" variant="primary" size='sm' />
                    </div>
                    :
                    <div
                        className='d-flex flex-column align-items-end'
                        style={{
                            width: '100%'
                        }}
                    >
                        <div
                            className='mb-3'
                        >
                            {
                                (
                                    order.order_status_id === 0
                                    ||
                                    order.order_status_id === 3
                                )
                                &&
                                <Button
                                    variant="info"
                                    onClick={() => {
                                        fnSendTemplateMessage(order.id, 'order_is_ready');
                                    }}
                                >
                                    {
                                        fetching === 'order_message_sending' ?

                                            <Spinner animation="grow" variant="primary" size='sm' />
                                            :
                                            <>
                                                {t("btn_send_message")} "{t("order_is_ready")}
                                            </>
                                    }

                                </Button>
                            }

                        </div>

                        <ButtonGroup
                            aria-label="Buttons to change order status"
                        >
                            <Button variant={order.order_status_id === 0 ? "primary" : "secondary"}
                                onClick={() => { fnOrderChangeStatus(order.id, 0) }}
                            >
                                {t('status_pending')}
                            </Button>
                            <Button variant={order.order_status_id === 3 ? "success" : "secondary"}
                                onClick={() => { fnOrderChangeStatus(order.id, 3) }}
                            >
                                {t('status_ready')}
                            </Button><Button variant={order.order_status_id === 4 ? "primary" : "secondary"}
                                onClick={() => { fnOrderChangeStatus(order.id, 4) }}
                            >
                                {t('status_delivered')}
                            </Button>
                        </ButtonGroup>
                    </div>
            }

        </Modal.Footer>


    </Modal>

}




const OrderList = ({
    order_type
}) => {

    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        fetching,
        orders,
        restaurant,
    } = dataReducer;

    const [show, setShow] = React.useState(false);
    const [orders_filtered, set_orders_filtered] = React.useState([]);


    React.useEffect(() => {
        set_orders_filtered(
            orders?.orders
                ?.filter(order => order.order_type === order_type)
                .sort((a, b) => { return new Date(a.ordered_for_day || a.order.order_dateTime) - new Date(b.ordered_for_day || b.order.order_dateTime) })
        )
    }, [orders, order_type]);

    if (orders === null || fetching === 'orders') {
        return <Container
            className='d-flex justify-content-center mt-5'
        >
            <Spinner animation="border" variant="primary" />
        </Container>
    }




    return (<>


        <ModalOrder
            show={show}
            setShow={setShow}
        />


        <Row
            className='m-0 p-0'
        >
            {

                orders_filtered?.map((order, index) => {

                    //show header when ordered_for_day is different


                    return (<>

                        {
                            order_type !== 'on_site' &&
                            (order.ordered_for_day_3 !== orders_filtered[index - 1]?.ordered_for_day_3) &&
                            <Row
                                key={'header_' + index}
                                className='text-center bg-dark text-light sticky m-0 p-1'
                            >
                                {fnProperCase(moment(order.ordered_for_day).tz(restaurant?.tz).format("dddd MMMM DD"))}
                            </Row>
                        }


                        <Col
                            key={index}
                            sm={6} lg={4} xxl={3}
                            className='p-1'
                        >
                            <Card
                                onClick={() => {

                                    dispatch({
                                        type: 'ORDER_SET',
                                        payload: {
                                            order
                                        }
                                    })
                                    setShow(true);

                                }}
                            >

                                <Card.Body>
                                    <Badge
                                        style={{
                                            marginBottom: 3
                                        }}
                                        bg={
                                            order.order_paid_dt === null ?
                                                "danger"
                                                :
                                                order.order_status_id === 0 ?
                                                    "dark"
                                                    :
                                                    "success"
                                        }>{index + 1}</Badge>

                                    <OrderDetails
                                        order={order}
                                        restaurant={restaurant}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                    )
                })
            }
        </Row>

    </>
    );
}


const ModalSearch = ({
    showModalSearch,
    setShowModalSearch
}) => {

    const { t } = useTranslation();
    return <Modal
        show={showModalSearch}
        onHide={() => setShowModalSearch(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('search')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Nav
                variant="pills"
                defaultActiveKey="link_orders_active"
                className='mb-2'
            >
                <Nav.Item>
                    <Nav.Link eventKey="link_orders_active">{t('orders_active')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link_orders_past">{t('orders_past')}</Nav.Link>
                </Nav.Item>
            </Nav>
            <Form>
                <Form.Group className="mb-3" controlId="formOrderNumber">
                    <Form.Control type="text" placeholder={t('order_number')} />
                </Form.Group >
                <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Control type="text" placeholder={t('phone_number_or_name')} />
                </Form.Group >
            </Form >
        </Modal.Body >
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModalSearch(false)}>
                {t("btn_close")}
            </Button>
            <Button variant="success">
                {t("btn_search")}
            </Button>
        </Modal.Footer>
    </Modal >

}

function Orders() {

    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = React.useState('pickup');

    const [showModalSearch, setShowModalSearch] = React.useState(false);

    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        fetching,
        orders
    } = dataReducer;


    React.useEffect(() => {
        dispatch({
            type: 'FETCHING',
            "fetching": "orders"
        });

        dispatch({
            type: 'ORDERS_FETCH',
            payload: {
                orderStatusFilter: 'open'
            }
        });

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <HeaderAdmin />
        <ModalSearch
            showModalSearch={showModalSearch}
            setShowModalSearch={setShowModalSearch}
        />
        <Container
            className='p-0'
        >
            <Container
                className='d-flex flex-row justify-content-between align-items-center sticky-top bg-light p-3 m-0'
            >
                <Nav
                    className='m-0'
                    variant="pills"
                    activeKey={selectedTab}
                    onSelect={(selectedKey) => setSelectedTab(selectedKey)}
                >
                    <Nav.Item
                        key={"pickup"}
                        style={{ position: 'relative' }}
                    >
                        <Nav.Link eventKey={"pickup"}

                        >

                            {t('pickup')}
                        </Nav.Link>
                        {
                            !!orders?.counters?.pickup

                            &&

                            <Badge
                                bg={selectedTab === 'pickup' ? 'danger' : 'primary'}
                                style={{ position: 'absolute', top: -10, right: -5 }}
                            >
                                {
                                    (orders?.counters?.pickup || 0)
                                }
                            </Badge>
                        }

                    </Nav.Item>
                    <Nav.Item
                        key={"delivery"}
                        style={{ position: 'relative', marginLeft: 10 }}
                    >
                        <Nav.Link eventKey={"delivery"}  >

                            {t('delivery')}
                        </Nav.Link>
                        {
                            !!orders?.counters?.delivery
                            &&

                            <Badge
                                bg={selectedTab === 'pickup' ? 'danger' : 'primary'}
                                style={{ position: 'absolute', top: -10, right: -5 }}
                            >
                                {orders?.counters?.delivery}
                            </Badge>
                        }

                    </Nav.Item>
                    <Nav.Item
                        key={"on_site"}
                        style={{ position: 'relative', marginLeft: 5 }}
                    >
                        <Nav.Link eventKey={"on_site"} >{t('on_site')}</Nav.Link>
                        {!!orders?.counters?.on_site &&
                            <Badge
                                bg={selectedTab === 'on_site' ? 'danger' : 'primary'}
                                style={{ position: 'absolute', top: -10, right: -5 }}
                            >
                                {
                                    (orders?.counters?.on_site)

                                }

                            </Badge>
                        }
                    </Nav.Item>

                </Nav>

                <Button
                    className="rounded-circle me-3"
                    variant='dark'
                    onClick={() => { setShowModalSearch(true) }}
                >
                    <IoSearch />
                </Button>


            </Container>


            {
                fetching === 'orders' ?
                    <Container
                        className='d-flex justify-content-center mt-5'
                    >
                        <Spinner animation="border" variant="primary" />
                    </Container>


                    :
                    <OrderList
                        order_type={selectedTab}
                    />
            }

        </Container >



    </>


}

export default Orders;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Form,
    Tabs,
    Tab
} from 'react-bootstrap';

const Horarios = ({
    setShow
}) => {


    const { t } = useTranslation();
    const DAYS_DATA = [
        { day: 'Monday', open: '10:00', close: '22:00' },
        { day: 'Tuesday', open: '10:00', close: '22:00' },
        { day: 'Wednesday', open: '10:00', close: '22:00' },
        { day: 'Thursday', open: '10:00', close: '22:00' },
        { day: 'Friday', open: '10:00', close: '22:00' },
        { day: 'Saturday', open: '10:00', close: '22:00' },
        { day: 'Sunday', open: '10:00', close: '22:00' },
    ];

    const [daysData, setDaysData] = React.useState(DAYS_DATA);
    const [key, setKey] = React.useState('home');
    const handleChange = (index, field, value) => {
        setDaysData((prevDaysData) =>
            prevDaysData.map((dayInfo, i) =>
                i === index ? { ...dayInfo, [field]: value } : dayInfo
            )
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted:', daysData);
        // Here, you can handle the form submission and update the state or perform any other desired actions.
    };
    return <>
        <Modal.Header closeButton>
            <Modal.Title>{t('settings_opening_hours')}</Modal.Title>
        </Modal.Header>
        <Modal.Body

        >
            <Tabs
                id="tabs-horarios"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-2"
            >
                <Tab eventKey="home" title="Tienda">

                </Tab>
                <Tab eventKey="profile" title="Pickups">

                </Tab>
                <Tab eventKey="contact" title="Deliveries">

                </Tab>
            </Tabs>


            <Form onSubmit={handleSubmit}>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>{t('day')}</th>
                            <th>{t('open')}</th>
                            <th>{t('close')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {daysData.map((dayInfo, index) => (
                            <tr
                                key={index}
                                className='align-middle'
                            >
                                <td>{t(dayInfo.day)}</td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        value={dayInfo.open}
                                        onChange={(e) => handleChange(index, 'open', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        value={dayInfo.close}
                                        onChange={(e) => handleChange(index, 'close', e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </Form>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={() => setShow(false)} data-bs-dismiss="modal">{t('Close')}</button>
            <button type="button" className="btn btn-primary">{t('Save changes')}</button>
        </Modal.Footer>

    </>

}

export default Horarios;

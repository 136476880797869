import React from 'react';
import HeaderAdmin from '@Components/HeaderAdmin';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

import { IoChevronForward } from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import {
    General,
    Horarios,
    Users,
    Whatsapp
} from '@Components/Settings/';



const Deliveries = ({
    setShow
}) => {
    const { t } = useTranslation();

    return <>
        <Modal.Header closeButton>
            <Modal.Title>{t('settings_deliveries')}</Modal.Title>
        </Modal.Header>
        <Modal.Body

        >
            <Container>
                Tarifas de envío
                <br />
                Google Maps API Key / City Setup
            </Container>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={() => setShow(false)} data-bs-dismiss="modal">{t('Close')}</button>
            <button type="button" className="btn btn-primary">{t('Save changes')}</button>
        </Modal.Footer>

    </>
}


function Settings() {

    const { t } = useTranslation();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
    } = dataReducer;

    const [show, setShow] = React.useState(false);

    return <>


        <Modal
            show={!!show}
            //fullscreen only on small screens
            fullscreen="sm-down"
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
        >

            {show === 'opening_hours' && <Horarios setShow={setShow} />}
            {show === 'general' && <General setShow={setShow} />}
            {show === 'users' && <Users setShow={setShow} />}
            {show === 'deliveries' && <Deliveries setShow={setShow} />}
            {show === 'whatsapp' && <Whatsapp setShow={setShow} />}


        </Modal>


        <HeaderAdmin />
        <Container>
            <Container
                className='d-flex justify-content-between align-items-center mt-0 mb-0'

            >
                <h1 style={{ display: 'contents' }}>{t('settings')}</h1>
                <img
                    alt=""
                    src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`}

                    height="30"
                    className="d-inline-block align-top"
                />
            </Container>

            <ListGroup
                className='mt-3'
                style={{
                    maxWidth: 350,
                }}
            >

                <ListGroup.Item
                    onClick={() => setShow('general')}
                    className='d-flex justify-content-between align-items-center cursor-pointer'
                >
                    <div  >
                        {t('settings_general')}
                    </div>
                    <IoChevronForward />
                </ListGroup.Item>

                <ListGroup.Item
                    onClick={() => setShow('users')}
                    className='d-flex justify-content-between align-items-center'
                >
                    <div  >
                        {t('settings_users')}
                    </div>
                    <IoChevronForward />
                </ListGroup.Item>


                <ListGroup.Item
                    onClick={() => setShow('opening_hours')}
                    className='d-flex justify-content-between align-items-center'
                >
                    <div  >
                        {t('settings_opening_hours')}
                    </div>
                    <IoChevronForward />
                </ListGroup.Item>

                <ListGroup.Item
                    onClick={() => setShow('whatsapp')}
                    className='d-flex justify-content-between align-items-center'
                >
                    <div  >
                        {t('settings_whatsapp')}
                    </div>
                    <IoChevronForward />
                </ListGroup.Item>


                <ListGroup.Item
                    onClick={() => setShow('deliveries')}
                    className='d-flex justify-content-between align-items-center'
                >
                    <div  >
                        {t('settings_deliveries')}
                    </div>
                    <IoChevronForward />
                </ListGroup.Item>


            </ListGroup >
        </Container>

    </>


}
export default Settings;

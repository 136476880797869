import HeaderAdmin from '@Components/HeaderAdmin';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,


    PointElement,
    LineElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';


const { faker } = require('@faker-js/faker');

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    plugins: {
        title: {
            display: false,
            text: 'Sales',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const labels = ['February', 'March', 'April', 'May', 'June', 'July'];
const data = {
    labels,
    datasets: [
        {
            label: 'Pasteles',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgb(255, 99, 132)',
        },
        {
            label: 'Tartas',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgb(75, 192, 192)',
        },
        {
            label: 'Tostadas',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgb(53, 162, 235)',
        },
    ],
};










const options2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom'
        },
        title: {
            display: false,
            text: '',
        },
    },
};


const data2 = {
    labels,
    datasets: [
        {
            label: 'Ventas',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Tickets',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};



function Reports() {

    const { t } = useTranslation();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
    } = dataReducer;


    return <>
        <HeaderAdmin />
        <Container
        >
            <Container
                className='d-flex justify-content-between align-items-center mt-2 mb-2'

            >
                <h1 style={{ display: 'contents' }}>{t('Reports')}</h1>
                <img
                    alt=""
                    src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`}

                    height="30"
                    className="d-inline-block align-top"
                />
            </Container>

            <Bar options={options} data={data} />
            <hr />
            <Line options={options2} data={data2} />

        </Container>



    </>


}

export default Reports;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './app/store'


//const Home = React.lazy(() => import('@Screens/Home'));
import Orders from '@Screens/Orders';
import Menu from '@Screens/Menu';
import Dashboard from '@Screens/Dashboard';
import Reports from '@Screens/Reports';
import Home from '@Screens/Home';
import Login from '@Screens/Login';
import Settings from '@Screens/Settings';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Suspense fallback={<div style={{ padding: 20, textAlign: 'center' }}>Loading...</div>}>
        <BrowserRouter>
          <Routes>


            <Route path='/'
              element={<Home />}
            />
            <Route path='/Login'
              element={<Login />}
            />

            <Route path='/admin/:stub/'
              element={<Dashboard />}
            />
            <Route path='/admin/:stub/orders'
              element={<Orders />}
            />
            <Route path='/admin/:stub/menu'
              element={<Menu />}
            />

            <Route path='/admin/:stub/reports'
              element={<Reports />}
            />

            <Route path='/admin/:stub/settings'
              element={<Settings />}
            />
            <Route
              path="*"
              element={<App />}
            />

          </Routes>
        </BrowserRouter>
      </Suspense>

    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import HeaderAdmin from '@Components/HeaderAdmin';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Row, Col, Badge } from 'react-bootstrap';

function DashboardScreen() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
        orders
    } = dataReducer;



    React.useEffect(() => {


        dispatch({
            type: 'ORDERS_FETCH',
            payload: {
                orderStatusFilter: 'open'
            }
        });




    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return <><HeaderAdmin />

        <Container>

            <Container
                className='d-flex justify-content-between align-items-center mt-2 mb-0'

            >
                <h1 style={{ display: 'contents' }}>{t('Dashboard')}</h1>
                <img
                    alt=""
                    src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`}

                    height="30"
                    className="d-inline-block align-top"
                />
            </Container>
            <Row className="justify-content-center">
                <Col sm={6}  >
                    <Card
                        className='mt-3'
                    >
                        <Card.Body>

                            <Card.Title>

                                {t('Orders')}

                            </Card.Title>

                            <Link to={`/admin/${restaurant?.stub}/orders`}

                            >
                                {t('Orders')}
                                {!!orders &&
                                    <Badge
                                        bg={'primary'}
                                        className='ms-2'
                                    >
                                        {orders?.open_orders_count}
                                    </Badge>
                                }
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6}  >
                    <Card
                        className='mt-3'
                    >
                        <Card.Body>

                            <Card.Title>{t('Menu')}</Card.Title>

                            <Link to={`/admin/${restaurant?.stub}/menu`}

                            >
                                {t('Menu')}
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6}  >
                    <Card
                        className='mt-3'
                    >
                        <Card.Body>

                            <Card.Title>{t('Reports')}</Card.Title>

                            <Link to={`/admin/${restaurant?.stub}/reports`}

                            >
                                {t('Reports')}
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6}  >
                    <Card
                        className='mt-3'
                    >
                        <Card.Body>

                            <Card.Title>{t('settings')}</Card.Title>

                            <Link to={`/admin/${restaurant?.stub}/settings`}

                            >
                                {t('settings')}
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>




        </Container>
    </>
}

export default DashboardScreen;

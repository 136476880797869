import React, { useState, useRef, useEffect } from 'react';
import HeaderAdmin from '@Components/HeaderAdmin';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';


import {
    IoOpenSharp,
    IoSearch,
    IoAdd,
    IoDocumentTextOutline,
    IoAddCircle,
    IoRemoveCircle,
    IoImage,
    IoImageOutline,
} from "react-icons/io5";
import { BsRobot } from "react-icons/bs";
import {
    Modal,
    Button,
    Table,
    Spinner,
    Form,
    ButtonGroup,
    Badge,
    Tabs,
    Tab
} from 'react-bootstrap';


import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { Card, Container, Row, Col } from 'react-bootstrap';


const ImageFileUploader = ({
    fnHidePhoto
}) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        return () => {
            // Cleanup function to revoke the object URL
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
                dispatch({
                    type: 'FOOD_ITEM_PHOTO_BASE64_SET',
                    data: {
                        photo_base64: null
                    }
                });
            }
        };
    }, [imagePreview]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            fnHidePhoto(true);
            //setSelectedFile(file);
            resizeAndCropImage(file, 150, 150)
                .then((resizedDataUrl) => {
                    setImagePreview(resizedDataUrl);
                })
                .catch((error) => console.error(error));

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1]; // Get the Base64 string without the "data:image/jpeg;base64," prefix
                console.log('Base64 String:', base64String);
                dispatch({
                    type: 'FOOD_ITEM_PHOTO_BASE64_SET',
                    data: {
                        photo_base64: base64String
                    }
                });

            };

            reader.readAsDataURL(file);
        } else {
            fnHidePhoto(false);
            //setSelectedFile(null);
            setImagePreview(null);
        }
    };

    const resizeAndCropImage = (file, size) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                let width = img.width;
                let height = img.height;

                // Calculate the new dimensions for cropping
                let newSize = Math.min(width, height);
                let offsetX = (width - newSize) / 2;
                let offsetY = (height - newSize) / 2;

                // Create a canvas with the new dimensions
                const canvas = document.createElement('canvas');
                canvas.width = size;
                canvas.height = size;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, offsetX, offsetY, newSize, newSize, 0, 0, size, size);

                canvas.toBlob((blob) => {
                    const croppedFile = new File([blob], file.name, { type: file.type });
                    const croppedDataUrl = URL.createObjectURL(croppedFile);
                    resolve(croppedDataUrl);
                }, file.type);
            };

            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleChooseFile = () => {

        fileInputRef.current.click();
    };

    return (
        <div
            className='d-flex flex-column align-items-center'

        >
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />


            {!imagePreview ? <>
                <Button variant="outline-dark" onClick={handleChooseFile} className='mt-1' size={'sm'}>
                    Choose
                </Button>
            </>
                :

                <div
                    className='d-flex flex-column align-items-center'
                ><div
                    className='d-flex justify-content-center align-items-center me-1 mb-1'
                    style={{
                        border: '1px solid #c0c0c0',
                        backgroundColor: '#c0c0c0',
                        borderRadius: 5,
                        width: 110,
                        height: 110,

                    }}
                >
                        <img src={imagePreview} alt='' style={{ width: '100px', }} />
                    </div>
                    <Button variant="outline-primary"
                        onClick={() => {
                            setImagePreview(null);
                            //setSelectedFile(null);
                            fnHidePhoto(false);
                        }}
                        size='sm'
                        className='mt-1'
                    >
                        Cancel
                    </Button>
                </div>
            }
        </div>
    );
};


const ItemEdit = () => {
    const { t } = useTranslation();
    const dataReducer = useSelector(state => state.dataReducer);
    const dispatch = useDispatch();


    const [newPhotoSelected, setNewPhotoSelected] = useState(false);

    const {
        restaurant,
        food_item,
        categories,
        fetching
    } = dataReducer;

    const [extras, setExtras] = useState(food_item?.extras || []);
    const [requiredOption, setRequiredOption] = useState(food_item?.required_option || []);
    const [extraNewName, setExtraNewName] = useState('');
    const [extraNewPrice, setExtraNewPrice] = useState('');
    const [optionNewName, setOptionNewName] = useState('');
    const [optionNewPrice, setOptionNewPrice] = useState('');

    const handleChangeExtraOption = (event, extra_option) => {
        const { name, value } = event.target;
        let name_or_price = name.split('_')[0];
        let ix = name.split('_')[1];
        if (extra_option === 'option') {
            let newRequiredOption = requiredOption.map((option, index) => {
                if (index === parseInt(ix)) {
                    return { ...option, [name_or_price]: value }
                }
                return option
            });
            setRequiredOption(newRequiredOption);
        } else {
            let newExtras = extras.map((extra, index) => {
                if (index === parseInt(ix)) {
                    return { ...extra, [name_or_price]: value }
                }
                return extra
            });
            setExtras(newExtras);
        }
    };



    const [formData, setFormData] = useState({
        id: food_item?.id || 0,
        name: food_item?.name || '',
        description: food_item?.description || '',
        categoryId: food_item?.food_category_id || '',
        price: food_item?.price || '',
        in_menu: food_item?.in_menu || false,
        sold_out: food_item?.sold_out || false,
        featured: food_item?.featured || false,
        pickup: food_item?.pickup || false,
        delivery: food_item?.delivery || false,
        dinein: food_item?.dinein || false,
    });


    const switchButtons = [
        {
            label: t('publish'),
            defaultChecked: formData.in_menu ? true : false,
            onChange: (checked) => {
                setFormData((prevFormData) => ({ ...prevFormData, in_menu: checked }));
            },
        },
        {
            label: t('sold_out'),
            defaultChecked: formData.sold_out,
            onChange: (checked) => {
                setFormData((prevFormData) => ({ ...prevFormData, sold_out: checked }));
            },
        },
        {
            label: t('featured'),
            defaultChecked: formData.featured,
            onChange: (checked) => {
                setFormData((prevFormData) => ({ ...prevFormData, featured: checked }));
            },
        },
    ];






    const buttons = [
        {
            label: t('pickup'),
            formDataProperty: 'pickup',
        },
        {
            label: t('delivery'),
            formDataProperty: 'delivery',
        },
        {
            label: t('on_site'),
            formDataProperty: 'dinein',
        },
        // Add more button configurations as needed
    ];
    const handleButtonClick = (property) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [property]: !prevFormData[property],
        }));
    };






    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
    const handleCategoryChange = (selectedOption) => {
        setFormData((prevFormData) => ({ ...prevFormData, categoryId: selectedOption.id }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        //validate name , description, price cant be empty
        //validate category is selected
        //validate price is a number

        formData.price = (formData.price + "").trim();
        formData.name = formData.name.trim();
        formData.description = formData.description.trim();


        let errors = [];
        if (formData.name === '') {
            errors.push(t('name'));
        }

        if (formData.price === '') {
            errors.push(t('price'));
        }
        if (formData.categoryId === '') {
            errors.push(t('category'));
        }
        if (errors.length > 0) {
            alert(`${t('Please fill the following fields:')} ${errors.join(', ')}`);
            return;
        }


        formData.price = parseFloat(formData.price);

        formData.extras = extras;
        formData.required_option = requiredOption;
        dispatch({
            type: 'FETCHING',
            fetching: {
                ...fetching || {},
                food_item_save: true
            }

        })


        console.log("formData", formData);

        setTimeout(() => {
            dispatch({
                type: 'FOOD_ITEM_SAVE',
                payload: {
                    food_item: formData
                }
            });
        }, 1000);

    };

    const [key, setKey] = useState('Main');

    const Main = () => <>

        <div>
            <ButtonGroup>
                {buttons.map((button, index) => (
                    <Button
                        key={index}
                        variant={formData[button.formDataProperty] ? 'success' : 'secondary'}
                        onClick={() => handleButtonClick(button.formDataProperty)}
                    >
                        {button.label}
                    </Button>
                ))}
            </ButtonGroup>
        </div>


        <div className='d-flex flex-row  align-items-end mb-3 mt-3'>
            {switchButtons.map((config, index) => (
                <div key={index} className={index > 0 ? 'ms-4' : ''}>
                    {config.label}
                    <br />
                    <BootstrapSwitchButton
                        width={50}
                        checked={config.defaultChecked}
                        onlabel='Yes'
                        offlabel='No'
                        onChange={config.onChange}
                        onstyle='success'
                        offstyle='danger'
                    />
                </div>
            ))}
        </div>
    </>

    const Extras = () => {


        return <div>

            <Table
                striped
                bordered
                hover

            >

                <tbody>
                    {
                        extras?.map((extra, index) => {
                            return <tr
                                key={index + "extra"}

                            >
                                <td>
                                    <Form.Control
                                        type="text"
                                        name={`name_${index}`}
                                        value={extra.name}
                                        onChange={(e) => handleChangeExtraOption(e, 'extra')}
                                        placeholder="Name"
                                        style={{
                                            flex: 1,
                                            marginRight: 10
                                        }}
                                    />
                                </td>
                                <td
                                    className='d-flex flex-row align-items-center'
                                >
                                    <Form.Control
                                        type="text"
                                        name={`price_${index}`}
                                        value={extra.price || "0"}
                                        onChange={(e) => handleChangeExtraOption(e, 'extra')}
                                        placeholder=""
                                        style={{
                                            width: 50,
                                            marginRight: 10
                                        }}
                                    />
                                    {food_item.currency}
                                    <Button

                                        variant="danger"
                                        className='ms-2'
                                        size='sm'
                                        onClick={() => {
                                            let newExtras = extras.filter((extra, ix) => {
                                                return ix !== index
                                            });
                                            setExtras(newExtras);
                                        }}
                                    >
                                        <IoRemoveCircle />
                                    </Button>

                                </td>
                            </tr>
                        })
                    }
                    <tr  >
                        <td>
                            <Form.Control

                                type="text"
                                name={`extra_new_name`}
                                value={extraNewName}
                                onChange={(e) => setExtraNewName(e.target.value)}
                                placeholder="New Extra Name"
                                style={{
                                    flex: 1,
                                    marginRight: 10,
                                    backgroundColor: '#c0c0c0',
                                }}
                            />
                        </td>
                        <td
                            className='d-flex flex-row align-items-center'
                        >
                            <Form.Control
                                type="text"
                                name={`extra_new_price`}
                                value={extraNewPrice}
                                onChange={(e) => setExtraNewPrice(e.target.value)}
                                placeholder=""
                                style={{
                                    width: 50,
                                    marginRight: 10,
                                    backgroundColor: '#c0c0c0'
                                }}
                            />
                            {food_item.currency}
                            <Button
                                variant={extraNewName === '' ? "secondary" : "success"}
                                disabled={extraNewName === ''}
                                className='ms-2'
                                size='sm'
                                onClick={() => {
                                    setExtras([...extras, {
                                        name: extraNewName,
                                        price: extraNewPrice
                                    }])
                                    setExtraNewName('');
                                    setExtraNewPrice('');
                                }}
                            >
                                <IoAddCircle />
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    }

    const Options = () =>

        <div>

            <Table
                striped
                bordered
                hover

            >
                <tbody>
                    {requiredOption?.map((option, index) => {
                        return <tr

                            key={index + "option"}
                        >

                            <td>
                                <Form.Control
                                    type="text"
                                    name={`name_${index}`}
                                    value={option.name}
                                    onChange={(e) => handleChangeExtraOption(e, 'option')}
                                    placeholder="Name"
                                    style={{
                                        flex: 1,
                                        marginRight: 10
                                    }}
                                />
                            </td>
                            <td
                                className='d-flex flex-row align-items-center'
                            >
                                <Form.Control
                                    type="text"
                                    name={`price_${index}`}
                                    value={option.price || "0"}
                                    onChange={(e) => handleChangeExtraOption(e, 'option')}
                                    placeholder=""
                                    style={{
                                        width: 50,
                                        marginRight: 10
                                    }}
                                />
                                {food_item.currency}
                                <Button
                                    variant="danger"
                                    className='ms-2'
                                    size='sm'
                                    onClick={() => {
                                        let newRequiredOption = requiredOption.filter((option, ix) => {
                                            return ix !== index
                                        });
                                        setRequiredOption(newRequiredOption);
                                    }}
                                >
                                    <IoRemoveCircle />
                                </Button>

                            </td>


                        </tr>
                    })}
                    <tr  >
                        <td>
                            <Form.Control

                                type="text"
                                name={`option_new_name`}
                                value={optionNewName}
                                onChange={(e) => setOptionNewName(e.target.value)}
                                placeholder="New Option Name"
                                style={{
                                    flex: 1,
                                    marginRight: 10,
                                    backgroundColor: '#c0c0c0'
                                }}
                            />
                        </td>
                        <td
                            className='d-flex flex-row align-items-center'
                        >
                            <Form.Control
                                type="text"
                                name={`option_new_price`}
                                value={optionNewPrice}
                                onChange={(e) => setOptionNewPrice(e.target.value)}
                                placeholder=""
                                style={{
                                    width: 50,
                                    marginRight: 10,
                                    backgroundColor: '#c0c0c0'
                                }}
                            />
                            {food_item.currency}
                            <Button
                                variant={optionNewName === '' ? "secondary" : "success"}
                                disabled={optionNewName === ''}
                                className='ms-2'
                                size='sm'
                                onClick={() => {
                                    setRequiredOption([...requiredOption, {
                                        name: optionNewName,
                                        price: optionNewPrice
                                    }])
                                    setOptionNewName('');
                                    setOptionNewPrice('');
                                }}
                            >
                                <IoAddCircle />
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </Table>

        </div>

    return <>



        <Modal.Body
        >

            <Form.Group controlId="formCategory"
                className='mb-1 mt-0'
                style={{
                    width: 220
                }}
            >
                <Select
                    options={categories}
                    value={categories.find(obj => obj.id === formData.categoryId) || { name: 'Select a category' }}
                    onChange={handleCategoryChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder={t('Category')}
                />
            </Form.Group>
            <Form.Group controlId="formName"
                className='mb-1 mt-1'
            >
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={t('Name')}
                />
            </Form.Group>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <div>
                    {!newPhotoSelected && <div
                        className='d-flex justify-content-center align-items-center me-1'
                        style={{
                            border: '1px solid #c0c0c0',
                            backgroundColor: '#c0c0c0',
                            borderRadius: 5,
                            width: 110,
                            height: 110,

                        }}
                    >


                        {
                            !!food_item?.photo_name ?
                                <img

                                    alt=""
                                    src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/${food_item?.photo_name}`}
                                    height="100"
                                    style={{
                                        borderRadius: 5
                                    }}
                                />
                                :
                                <IoImage size={25} />
                        }

                    </div>
                    }
                    <div>
                        <ImageFileUploader
                            fnHidePhoto={(v) => {
                                setNewPhotoSelected(v);
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        flex: 1
                    }}
                >

                    <Form.Group controlId="formDescription"
                        className='mb-1 mt-1'
                    >
                        <Form.Control
                            as="textarea"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder={t('Description')}
                            rows={4}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice"
                        className='mb-1 mt-1 d-flex flex-row align-items-center'
                    >
                        <Form.Control
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            placeholder={t('Price')}
                            style={{
                                width: 100,
                                marginRight: 10
                            }}
                        />

                        {food_item.currency}
                    </Form.Group>

                </div>



            </div>



            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >

                <Tab eventKey="Main" title="Main">
                    {Main()}
                </Tab>

                <Tab eventKey="Extras" title="Extras">
                    {Extras()}
                </Tab>
                <Tab eventKey="Options" title="Options">
                    {Options()}
                </Tab>

            </Tabs>





        </Modal.Body>
        <Modal.Footer
            className='d-flex justify-content-between align-items-center'
        >
            <Button
                disabled={fetching?.food_item_delete || fetching?.food_item_save}
                onClick={handleSubmit}
                variant="outline-danger"
                style={{ width: 80 }}
            >
                {
                    fetching?.food_item_delete ?
                        <Spinner animation="border" variant="light" size="sm" />
                        :
                        t('delete')
                }

            </Button>


            <Button
                variant="outline-primary"
            >
                <BsRobot />
            </Button>
            <Button
                disabled={fetching?.food_item_save}
                onClick={handleSubmit}
                className="btn btn-primary"
                style={{ width: 100 }}
            >
                {
                    fetching?.food_item_save ?
                        <Spinner animation="border" variant="light" size="sm" />
                        :
                        t('save')
                }

            </Button>
        </Modal.Footer>




    </>
}


const ModalSearch = ({
    showModalSearch,
    setShowModalSearch
}) => {

    const { t } = useTranslation();



    const [soldOut, setSoldOut] = React.useState(true);
    const [featured, setFeatured] = React.useState(true);
    const [published, setPublished] = React.useState(true);

    const dataReducer = useSelector(state => state.dataReducer);
    const {
        categories
    } = dataReducer;

    const handleCategoryChange = (selectedOption) => {
        console.log(selectedOption)
    };

    return <Modal
        show={showModalSearch}
        onHide={() => setShowModalSearch(false)}
        backdrop="static"
        keyboard={false}
        size="md"
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('search')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Container
                className='mb-2 p-0 d-flex flex-row'
            >

                <Button
                    onClick={() => { setPublished(!published) }}
                    variant={published ? "info" : "info-outline"}
                >
                    {t('Published')}
                </Button>
                &nbsp;

                <Button
                    onClick={() => { setSoldOut(!soldOut) }}
                    variant={soldOut ? "info" : "info-outline"}
                >

                    {t('Sold Out')}
                </Button>
                &nbsp;
                <Button
                    onClick={() => { setFeatured(!featured) }}
                    variant={featured ? "info" : "info-outline"}
                >

                    {t('Featured')}
                </Button>
            </Container>

            <Select
                options={categories}
                // 
                onChange={handleCategoryChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t('Category')}
            />

            <Form>
                <Form.Group className="mb-1 mt-1" controlId="formPhone">
                    <Form.Control type="text" placeholder={t('search')} />
                </Form.Group >
            </Form >
        </Modal.Body >
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModalSearch(false)}>
                {t("btn_close")}
            </Button>
            <Button variant="primary">
                {t("btn_search")}
            </Button>
        </Modal.Footer>

    </Modal >

}


const ModalItem = ({
    show,
    setShow,
}) => {

    const { t } = useTranslation();

    const dataReducer = useSelector(state => state.dataReducer);
    const {
        food_item,
    } = dataReducer;

    return <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}

        fullscreen={"sm-down"}
    >
        <Modal.Header closeButton>
            <Modal.Title id="exampleModalLabel">
                {food_item?.name || t('new_item')}
            </Modal.Title>

        </Modal.Header>
        <ItemEdit />

    </Modal>

}

const List = () => {

    const { t } = useTranslation();
    const [show, setShow] = React.useState(false);

    const dispatch = useDispatch();

    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
        menu,
        fetching
    } = dataReducer;

    if (menu === null || fetching === 'menu') {
        return <Container
            className='d-flex justify-content-center mt-5'
        >
            <Spinner animation="border" variant="primary" />
        </Container>
    }


    return <>
        <ModalItem
            show={show}
            setShow={setShow}
        />

        <Row>
            {
                menu?.map((item, index) => {
                    return <>


                        {(index === 0 || item.food_category_name !== menu[index - 1].food_category_name) && <div
                            key={index}
                            className='bg-primary text-white sticky-top'
                        >
                            <h5 className='m-1' >
                                {item.food_category_name}
                            </h5>
                        </div>
                        }



                        <Col sm={6} lg={4} xxl={3}>
                            <Card

                                key={index}
                                className='mb-1 mt-1'
                                onClick={() => {
                                    dispatch({
                                        type: 'FOOD_ITEM_SET',
                                        data: {
                                            food_item: item
                                        }
                                    });
                                    setShow(true);

                                }}
                            >
                                <Card.Body>
                                    <Card.Title
                                        className='d-flex justify-content-between align-items-center'
                                    >
                                        <div>
                                            {item.name}
                                        </div>
                                        <div>
                                            {item.price_formatted}
                                        </div>

                                    </Card.Title>
                                    <Card.Text
                                        className='d-flex justify-content-between align-items-center'

                                    >
                                        {!item.in_menu ? <Badge bg="warning">{t('not_published')}</Badge> : <div></div>}
                                        {!!item.sold_out ? <Badge bg="danger">{t('sold_out')}</Badge> : <div></div>}
                                        {item.featured ? <Badge bg="success">{t('featured')}</Badge> : <div></div>}
                                    </Card.Text>
                                    <Card.Text
                                        className='d-flex align-items-top'
                                    >
                                        {!item.photo_name ?
                                            <IoImageOutline size={35} color={"red"} />
                                            :
                                            <img

                                                alt=""
                                                src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/${item.photo_name}`}
                                                height="100"
                                                className="float-left"
                                                style={{
                                                    borderRadius: 5
                                                }}
                                            />
                                        }
                                        <div
                                            style={{
                                                paddingLeft: 10
                                            }}
                                        >

                                            {item.food_category_name}
                                            <br />
                                            {item.description}
                                        </div>


                                    </Card.Text>



                                    <Card.Text>


                                        {
                                            item.extras?.map((extra, index) => {
                                                return <div
                                                    key={index + "extra"}
                                                >
                                                    {extra.name}
                                                    &nbsp;
                                                    {extra.price}
                                                    &nbsp;
                                                    {item.currency}
                                                </div>
                                            })
                                        }

                                        {item?.required_option?.map((option, index) => {
                                            return <div

                                                key={index + "option"}
                                            >
                                                {option.name}
                                                &nbsp;
                                                {option.price}
                                                &nbsp;
                                                {item.currency}
                                            </div>
                                        })}
                                    </Card.Text>
                                </Card.Body>
                            </Card >
                        </Col >
                    </>
                })
            }
        </Row >
    </>
}


const ActionBar = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        restaurant,
    } = dataReducer;

    const [showModalSearch, setShowModalSearch] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [show2, setShow2] = React.useState(false);

    return <>
        <Modal
            show={!!show2}
            onHide={() => setShow2(false)}
            backdrop="static"
            keyboard={false}
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    show2 === 'robot' &&
                    <div>
                        * generate gpt descriptions *
                    </div>
                }
                {
                    show2 === 'xls' &&
                    <div>
                        * download xls file with menu information *
                    </div>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow2(false)}>
                    {t("btn_close")}
                </Button>
                <Button variant="primary">
                    {t("OK")}
                </Button>
            </Modal.Footer>

        </Modal>
        <ModalSearch

            showModalSearch={showModalSearch}
            setShowModalSearch={setShowModalSearch}
        />
        <ModalItem
            show={show}
            setShow={setShow}
        />
        <ButtonGroup
            className='mt-2 mb-2'
        >
            <Button
                variant="outline-dark"
                onClick={() => {
                    setShow2("robot")
                }}
            >
                <BsRobot />
            </Button>

            <Button
                variant="outline-dark"
                onClick={() => {
                    setShow2("xls")
                }}
            >
                <IoDocumentTextOutline />
            </Button>
            <Button
                variant="outline-primary"
                onClick={() => {
                    dispatch({
                        type: 'FOOD_ITEM_SET',
                        data: {
                            food_item: {}
                        }
                    });
                    setShow(true);
                }}
            >
                <IoAdd />
            </Button>
            <Button
                variant="outline-primary"
                onClick={() => {

                    setShowModalSearch(true)
                }}
            >
                <IoSearch />
            </Button>
        </ButtonGroup>
        <a
            href={`https://sagamenu.com/${restaurant?.stub}/`}
            target='_blank'
            rel="noreferrer noopener"
        >
            Menu
            <IoOpenSharp />
        </a>
    </>
}


function Menu() {

    const dispatch = useDispatch();
    const { t } = useTranslation();



    React.useEffect(() => {
        dispatch({
            type: 'FETCHING',
            "fetching": "menu"
        });

        dispatch({
            type: 'MENU_FETCH',
            payload: {
                //"menu_status": 'in_menu'
            }
        });

        dispatch({
            type: 'CATEGORIES_FETCH'
        });

    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    return <>
        <HeaderAdmin />

        <Container

        >
            <Container
                className='
                    d-flex 
                    d-flex-row
                    justify-content-between
                    align-items-center
                    m-0
                    p-0
                '
            >
                <div>
                    <h1>{t('Menu')}</h1>
                </div>




                <ActionBar />
            </Container>


            <List />

        </Container>
    </>
}

export default Menu;
